import React from "react";
import Hero from "../components/Hero";
import Banner from "../components/Banner";
import { Link } from "react-router-dom";
import Services from "../components/Services";
import FeturedRooms from "../components/FeaturedRooms";
import { useTranslation } from "react-i18next";
import Working from "../components/Working";
import img1 from "../images/slider/img1.jpg";
import img2 from "../images/slider/img2.jpg";
import img3 from "../images/slider/img3.jpg";
import img4 from "../images/slider/img4.jpg";
import img5 from "../images/slider/img5.jpg";

export default function Home() {
    const { t } = useTranslation();
    return (
        <>
            {/* <Hero>
                <Banner title={t("nameP")} subtitle={t("nameP")}>
                    <Link to="/services" classNameName="btn-primary font-main">
                        {t("services")}
                    </Link>
                </Banner>
            </Hero> */}

            <div className="container-fluid">
                <div className="row mx-0">
                    <div className="col-12 px-0" style={{ padding: 0 }}>
                        <div id="carouselExampleIndicators" className="carousel slide" style={{ marginTop: "77px"}} data-bs-ride="carousel">
                            <div className="carousel-indicators">
                                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2" ></button>
                                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3" ></button>
                                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="3" aria-label="Slide 3" ></button>
                                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="4" aria-label="Slide 3" ></button>
                            </div>
                            <div className="carousel-inner">
                                <div className="carousel-item active">
                                    <img src={img1} className="d-block w-100" style={{ maxHeight: "90vh" }} alt="..." />
                                </div>
                                <div className="carousel-item">
                                    <img src={img2} className="d-block w-100" style={{ maxHeight: "90vh" }} alt="..." />
                                </div>
                                <div className="carousel-item">
                                    <img src={img3} className="d-block w-100" style={{ maxHeight: "90vh" }} alt="..." />
                                </div>
                                <div className="carousel-item">
                                    <img src={img4} className="d-block w-100" style={{ maxHeight: "90vh" }} alt="..." />
                                </div>
                                <div className="carousel-item">
                                    <img src={img5} className="d-block w-100" style={{ maxHeight: "90vh" }} alt="..." />
                                </div>
                            </div>
                            <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev" >
                                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                <span className="visually-hidden">
                                    Previous
                                </span>
                            </button>
                            <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                <span className="visually-hidden">Next</span>
                            </button>
                        </div>
                        {/* <div id="carouselExampleIndicators" className="carousel slide" style={{ marginTop: "77px"}} data-ride="carousel">
                            <ol className="carousel-indicators">
                                <li data-target="#carouselExampleIndicators" data-slide-to="0" className="active"></li>
                                <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
                                <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
                            </ol>
                            <div className="carousel-inner">
                                <div className="carousel-item active">
                                    <img src={img1} className="d-block w-100" style={{ maxHeight: "90vh" }} alt="..." />
                                </div>
                                <div className="carousel-item">
                                    <img src={img2} className="d-block w-100" style={{ maxHeight: "90vh" }} alt="..." />
                                </div>
                                <div className="carousel-item">
                                    <img src={img3} className="d-block w-100" style={{ maxHeight: "90vh" }} alt="..." />
                                </div>
                                <div className="carousel-item">
                                    <img src={img4} className="d-block w-100" style={{ maxHeight: "90vh" }} alt="..." />
                                </div>
                                <div className="carousel-item">
                                    <img src={img5} className="d-block w-100" style={{ maxHeight: "90vh" }} alt="..." />
                                </div>
                            </div>
                            <a class="left carousel-control" href="#firstCarousel" role="button" data-slide="prev">
                                <span class="icon-prev" aria-hidden="true"></span>
                                <span class="sr-only">Previous</span>
                            </a> 
                            <button className="carousel-control-next" type="button" data-target="#carouselExampleIndicators" data-slide="next">
                                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                <span className="sr-only">Next</span>
                            </button>
                        </div> */}
                    </div>
                </div>
            </div>
            <Services />
            <FeturedRooms />
            <Working />
        </>
    );
}
