import React, { useState } from "react";
import Savinf from "../images/strong.png";
import Typing from "../images/typing.png";
import Gersy from "../images/gersy.png";
import Mira from "../images/mira.png";
import Post from "../images/post.png";
import Alstom from "../images/alstom.png";
import Ezone from "../images/ezone.png";
import Alnafora from "../images/alnafora.png";
import Looking from "../images/looking.png";
import Food from "../images/food.png";
import Card from "../images/card.png";
import psc from "../images/psc.png";
import coffe from "../images/coffe.png";
import arcom from "../images/arcom.png";
import losen from "../images/losen.png";
import adwan from "../images/adwan.png";
import ker from "../images/7ker.jpg";
import sort from "../images/sort.png";
import srok from "../images/srok.png";
import water from "../images/water.png";
import mohamed from "../images/mohamed.png";
import so from "../images/so.png";
import house from "../images/house.png";
import n from "../images/n.png";
import aren from "../images/aren.png";
import screen from "../images/screen.png";
import slk from "../images/slk.png";
import wts from "../images/wts.png";
import src from "../images/src.png";
import mrmr from "../images/mrmr.png";
import unviersity from "../images/unviersity.png";

import Title from "./Title";
import Slider from "react-slick";
import { useTranslation } from "react-i18next";

const FeaturedRooms = () => {
    const { t } = useTranslation();

    const [Clients, setClients] = useState([
        {
            id: 1,
            name: "الامن العام للطرق",
            img: Savinf,
        },
        {
            id: 2,
            name: "مجموعة عجلان واخوانه",
            img: Typing,
        },
        {
            id: 3,
            name: "الجريسي",
            img: Gersy,
        },
        {
            id: 4,
            name: "مجموعة ميرا فودز ",
            img: Mira,
        },
        {
            id: 5,
            name: "البريد الممتاز ",
            img: Post,
        },
        {
            id: 6,
            name: "شركة الستوم السعودية قطار الرياض",
            img: Alstom,
        },
        {
            id: 7,
            name: "مجموعة ايزون وتيلدا السعودية",
            img: Ezone,
        },
        {
            id: 8,
            name: "مطاعم النافورة",
            img: Alnafora,
        },
        {
            id: 9,
            name: "شركة المرقاب للمقاولات",
            img: Looking,
        },
        {
            id: 10,
            name: "مطاعم االفندي",
            img: Food,
        },
        {
            id: 11,
            name: "شركة دارمة كارديال ",
            img: Card,
        },
        {
            id: 12,
            name: "الشركة التموينية للمشاريع",
            img: psc,
        },
        {
            id: 13,
            name: "مجموعة بن زقر",
            img: coffe,
        },
        {
            id: 14,
            name: "شركة اركوم السعودية",
            img: arcom,
        },
        {
            id: 15,
            name: "مطاعم لوزين",
            img: losen,
        },
        {
            id: 16,
            name: "شركة عدوان للتسوق",
            img: adwan,
        },
        {
            id: 17,
            name: "مجموعة الحكير شركة االطعمه والترفيه",
            img: ker,
        },
        {
            id: 18,
            name: "مجموعة السريع للسجاد والمفروشات",
            img: sort,
        },
        {
            id: 19,
            name: "اطعمة الشرق المحدودة",
            img: srok,
        },
        {
            id: 20,
            name: "شركة صحتين للمياة (مويا)  ",
            img: water,
        },
        {
            id: 21,
            name: "مجموعة محمد العجيمي الصناعية",
            img: mohamed,
        },
        {
            id: 22,
            name: "مجموعة سكاراموش",
            img: so,
        },
        {
            id: 22,
            name: "مجموعة مطاعم نودل هاوس",
            img: house,
        },
        {
            id: 23,
            name: "الشركة الاهلية للا ستقدام",
            img: n,
        },
        {
            id: 24,
            name: "شركة العرين للمقاولات",
            img: aren,
        },
        {
            id: 25,
            name: "شركة شاشات القمم",
            img: screen,
        },
        {
            id: 26,
            name: "شركة اتحاد مصانع االسالك",
            img: slk,
        },
        {
            id: 27,
            name: "الشركة الوطنية للتقسيط",
            img: wts,
        },
        {
            id: 28,
            name: "المركز التشيكي للعالج الطبيعي",
            img: src,
        },
        {
            id: 29,
            name: "مجموعة وادي مرامر للمقاولات",
            img: mrmr,
        },
        {
            id: 30,
            name: "قاعدة الملك سلمان البحرية",
            img: unviersity,
        },
    ]);

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,
        arrows: true,
        responsive: [
            {
                
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    arrows: true,
            },
            },
            {
                breakpoint: 700,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2,
                arrows: true,
            },
            },
            {
                breakpoint: 480,
                settings: {
                arrows: true,
                slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },{
                breakpoint: 200,
                settings: {
                arrows: true,
                slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    return (
        <section className="featured-rooms">
            <Title title={t("FeaturedClients")} />
            <div className="container">
                <div className="row mx-0">
                    <div className="col-10 col-md-12 mx-auto">
                        <Slider {...settings}>
                            {Clients.map((item) => {
                                return (
                                    <div key={item.id} className="col-6 col-md-4 p-3 text-center">
                                        <img src={item.img} className="d-inline-block" style={{maxWidth:"170px"}} width="100%" height="170" alt={item.img} />
                                        {/* <h5 className="text-center py-2 mt-4 font-main">
                                            {item.name}
                                        </h5> */}
                                    </div>
                                );
                            })}
                        </Slider>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default FeaturedRooms;
