import React, { useState } from "react";
import { FaCalculator, FaFingerprint, FaNetworkWired } from "react-icons/fa";
import { BsFillCameraVideoFill, BsFillMicFill } from "react-icons/bs";
import { AiFillPhone, AiFillTool } from "react-icons/ai";
import { RiUserSearchFill } from "react-icons/ri";
import Title from "../components/Title";
import Hero from "../components/Hero";
import Banner from "../components/Banner";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import img1 from "../images/sevices/network.jpg";
import img2 from "../images/sevices/anter.jpg";
import img3 from "../images/sevices/img3.jpg";
import img4 from "../images/sevices/img4.jpg";
import img5 from "../images/sevices/anti.jpg";
import img6 from "../images/sevices/img6.jpg";
import img7 from "../images/sevices/img7.jpg";
import img8 from "../images/sevices/img8.jpg";
import img9 from "../images/sevices/img9.jpg";
import img10 from "../images/sevices/img10.jpg";
import img11 from "../images/sevices/img11.jpg";



function Services() {

    const { t , i18n } = useTranslation();
    let { language } = i18n;


    const [services, setServices] = useState([
        {
            icon: img8,
            title: t("sec4"),
        },
        
        {
            icon: img11,
            title: t("systementer"),
        },
        {
            icon: img1,
            title: t("InterNetNetworks"),
        },
        {
            icon: img2,
            title: t("anter"),
        },
        {
            icon: img4,
            title: t("SoundSystems"),
        },
        {
            icon: img3,
            title: t("newControl"),
        },
        {
            icon: img5,
            title: t("AntiTheftSystems"),
        },
        {
            icon: img6,
            title: t("centerlat"),
        },
        {
            icon: img7,
            title: t("soft"),
        },

        {
            icon: img9,
            title: t("POS"),
        },
        {
            icon: img10,
            title: t("bigline"),
        },

    ]);

    return (
        <>
            <Hero>
                <Banner title={t("services")} subtitle={t("services")} >
                    <Link to="/" className="btn-primary font-main">
                        { t('backhome') }
                    </Link>
                </Banner>
            </Hero>
            <div className="container my-5">
                <div className={ language == "ar" ? "row flex-row-reverse" : "row" }>
                    <Title title={t("services")} />
                    {services.map((item, index) => {
                        return (
                            <article key={index} className="col-10 mx-auto col-md-3 my-3 service font-main">
                                {/* <span> {item.icon} </span> */}
                                <img src={item.icon} width="250" height="200" />
                                <h5 className="mt-3"> {item.title} </h5>
                            </article>
                        );
                    })}
                </div>
            </div>
        </>
    );
}

export default Services;
