import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Banner from "../components/Banner";
import Hero from "../components/Hero";
import Title from "../components/Title";
import logo from '../images/logo2.jpg' 
import {AiFillPhone} from "react-icons/ai"
import {MdEmail} from "react-icons/md"
import {FaMailBulk, FaHotel} from "react-icons/fa"

export const ContactUs = () => {
    const { t, i18n } = useTranslation();
    const { language } = i18n;
    return (
        <>
            <Hero>
                <Banner title={t('contactus')} subtitle={t('contactus')}>
                    <Link to="/" className="btn-primary font-main">
                        {t('backhome')}
                    </Link>
                </Banner>
            </Hero>
            <div className="container my-5">
                <Title title={t('contactus')} />
                <div className={`row font-main ${language  == "ar" ? "contact-right" : "contact-left" }`}>
                    <div className="col-12 col-md-10 mx-auto">
                        <div className="text-center">
                        <img src={logo} alt="" style={{ maxWidth:"270px",width:"100%", maxHeight:"200px", height:"100%"}} />
                        </div>
                        <form action="https://formspree.io/f/moqkgrkw" method="post" dir={language == 'ar' ? "rtl" : "ltr"}>
                            <div className="form-group my-3">
                                <label htmlFor="name"> {t('name')} </label>
                                <input type="text" className="form-control" name="name" id="name" />
                            </div>
                            <div className="form-group my-3">
                                <label htmlFor="phone"> {t('phone')} </label>
                                <input type="number" className="form-control" name="phone" id="phone" />
                            </div>
                            <div className="form-group my-3">
                                <label htmlFor="email"> {t('email')} </label>
                                <input type="email" className="form-control" name="email" id="email" />
                            </div>
                            <div className="form-group my-3">
                                <label htmlFor="details"> {t('detils')} </label>
                                <textarea className="form-control" id="details"></textarea>
                            </div>
                            <div>
                                <button type="submit" className="btn btn-primary px-5">  {t('send')}  </button>
                            </div>
                        </form>
                    </div>

                    <div className="col-10 mx-auto mt-3 text-center">
                        <Title title={t('info')} />
                        <div className="bg-light py-3 rounded">
                            <div className="d-flex align-items-center justify-content-end">
                                <p className="m-0 pe-3"> صندوق بريد 59256 الرياض 11771 </p>
                                <FaMailBulk className="icon-style" />
                            </div>
                            <div className="d-flex align-items-center justify-content-end">
                                <p className="m-0 pe-3"> 0118031115 / 0114155699 / 0114155633 </p>
                                <AiFillPhone className="icon-style" />
                            </div>
                            <div className="d-flex align-items-center justify-content-end">
                                <a href="tel:0560802181" className="m-0 pe-3"> +966 560802181  </a>
                                <a href="tel:0560802181" className="m-0 pe-3"> +966 544112117 </a>
                                <FaHotel className="icon-style" />
                            </div>
                            <div className="d-flex align-items-center justify-content-end">
                                <a href="mailto:Info@sessentials.com" className="m-0 pe-3"> Info@sessentials.com </a>
                                <MdEmail className="icon-style" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
