import React from "react";

export default function Banner({ children, title, subtitle }) {
    return (
        <div className="banner py-5">
            <h2 className="font-main">{title}</h2>
            <div />
            {/* <p className="font-main">{subtitle}</p> */}
            {children}
        </div>
    );
}
