const arabic = {
    nameP: "مؤسســـة اساسيات الحلول للتجارة",
    sub: "حلول EST",
    services: "خدماتنا",
    SecurityCameras: "كاميرات مراقبة",
    AntiTheftSystems: "انظمه الانذار ضد السرقه",
    FingerprintSystems: "انظمة حضور وانصراف",
    TelephoneSystems: "سنترال واي بي تلفون",
    home: "الرئيسية",
    about: "من نحن",
    contactus: "اتصل بنا",
    FeaturedClients: "العملاء المميزون",
    backhome: "الرجوع الي الرئيسية",
    aboutus: "عن الشركة",
    specialize :": متخصصون فى",
    sec: "الانظمة الامنية ( احدث انواع كاميرات مراقبة و أجهزة الانذار ) -",
    sec2: "ادق اجهزة ضبط الحضور والانصراف -",
    sec3: "سنترالات و شبكات -",
    sec4: "كاميرات مراقبة",
    sec5: "أجهزة إنذار -",
    sec6: "أنظمة ضبط الحضور -",
    sec7: "أنظمة ضبط الحضور والانصراف -",
    sec8: "سنترالات و شبكات -",
    working : "شركائنا",
    InterNetNetworks: "شبكات الإنترنت",
    SoundSystems: "انظمة الصوت",
    MaintenanceContracts: "عقود الصيانة",
    IntercomSystem: "نظام الإتصال الداخلي",
    // 
    textabout: "هي مؤسسة احترافية في مجال تركيب و تشغيل و صيانة الانظمة الامنية المتكامله اخذت على عاتقها اضافة قيمة لتجربة العملاء عن طريق صياغة افضل كوادر العمل المحترفين تحت مفهوم ثقافة الالتزام و رضاء العميل",
    name : "الاسم",
    phone : "رقم الجوال",
    email : "البريد الالكتروني",
    detils : "تفاصيل",
    send: "ارسال",

    // 
    newControl : "اكسس كنترول",
    network: "الشبكات",
    anter: "انتركوم",
    centerlat: "سنترالات ",
    soft: "عقود صيانة",
    POS: "نقاط البيع",
    bigline: "بواباب تنظيم الدخول والخروج  للافراد و السيارات",
    info: "معلوماتنا",
    systementer: "انظمة الحضور والانصراف",


};

export default arabic;
