import React from "react";
import logo from "../images/logo2.jpg";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import arLang from "../images/ar.png";
import enLang from "../images/en.png";

const Navbar = () => {
    const { t, i18n } = useTranslation();
    const { language } = i18n;

    const onChangeLanguage = (lang) => {
        localStorage.setItem("langSolution", JSON.stringify(lang));
        window.location.reload();
        i18n.changeLanguage(lang);
    };

    const onClickLink = () => {
        document.querySelector('.navbar-collapse').classList.toggle('show')
    }

    return (
        <div className="container-fluid" style={{ background: "#fefefe" }}>
            <div className="container">
                <nav className="navbar navbar-expand-lg navbar-light" style={{ background: "#fefefe" }}>
                    <Link to="/">
                        <img src={logo} style={{maxHeight:'59px',maxWidth:"209px", height:"100%", width:"100%"}} alt="Beach Resort" />
                    </Link>
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>

                    <div className="collapse navbar-collapse justify-content-end" id="navbarSupportedContent">
                        <ul className="navbar-nav mr-auto">
                            <li className="nav-item active text-center py-1">
                                <Link className="nav-link font-main" to="/" onClick={onClickLink}>
                                    {t("home")}
                                </Link>
                            </li>
                            <li className="nav-item text-center py-1">
                                <Link className="nav-link font-main" to="/about-us" onClick={onClickLink}>
                                    {t("about")}
                                </Link>
                            </li>
                            <li className="nav-item text-center py-1">
                                <Link className="nav-link font-main" to="/services" onClick={onClickLink}>
                                    {t("services")}
                                </Link>
                            </li>
                            <li className="nav-item text-center py-1">
                                <Link className="nav-link font-main" to="/contact-us" onClick={onClickLink}>
                                    {t("contactus")}
                                </Link>
                            </li>
                            <li className="nav-item text-center py-1 mx-auto">
                                {language == "ar" ? (
                                    <a href="#" className="nav-link font-main link d-flex align-items-center" onClick={() => onChangeLanguage("en")} > 
                                        <span> English </span> <img src={enLang} alt="arabic" />
                                    </a>
                                ) : (
                                    <a href="#" className="nav-link font-main link d-flex align-items-center" onClick={() => onChangeLanguage("ar")} > 
                                        <img src={arLang} alt="arabic" /> <span> اللغة العربية </span>
                                    </a>
                                )}
                            </li>
                        </ul>
                    </div>
                </nav>
            </div>
        </div>
    );
};
export default Navbar;
