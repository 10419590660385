import React from "react";
import Hero from "../components/Hero";
import Banner from "../components/Banner";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
export default function Error() {
    const {t} = useTranslation();
    return (
        <Hero>
            <Banner title="404" subtitle="page not found">
                <Link to="/" className="btn-primary font-main">
                    {t('backhome')}
                </Link>
            </Banner>
        </Hero>
    );
}
