import React, { useState } from "react";
import { FaFingerprint } from "react-icons/fa";
import { BsFillCameraVideoFill } from "react-icons/bs";
import { AiFillPhone } from "react-icons/ai";
import { RiUserSearchFill } from "react-icons/ri";
import Title from "./Title";
import { useTranslation } from "react-i18next";
import img1 from "../images/sevices/network.jpg";
import img2 from "../images/sevices/anter.jpg";
import img3 from "../images/sevices/img3.jpg";
import img4 from "../images/sevices/img4.jpg";

function Services() {
    const { t , i18n } = useTranslation();
    let { language } = i18n;

    const [services, setServices] = useState([
        {
            icon: img1,
            title: t("InterNetNetworks"),
        },
        {
            icon: img2,
            title: t("anter"),
        },
        {
            icon: img3,
            title: t("newControl"),
        },
        {
            icon: img4,
            title: t("SoundSystems"),
        },
    ]);
    return (
        <>
            <div className="container my-5">
                <div className={ language == "ar" ? "row flex-row-reverse" : "row" }>
                    <Title title={t("services")} />
                    {services.map((item, index) => {
                        return (
                            <article key={index} className="col-10 col-md-3 mx-auto service font-main">
                                {/* <span> {item.icon} </span> */}
                                <img src={item.icon} width="250" height="200" />
                                <h5 className="mt-2"> {item.title} </h5>
                            </article>
                        );
                    })}
                </div>
            </div>
        </>
    );
}

export default Services;
