import React, { useState } from "react";

import samsung from "../images/samsung.jpg";
import avaya from "../images/avaya.jpeg";
import came from "../images/came.jpeg";
import commax from "../images/commax.jpeg";
import cisco from "../images/cisco.jpeg";
import zk from "../images/zk.jpeg";
import faac from "../images/faac.jpeg";
import ezvis from "../images/ezvis.jpeg";
import dahua from "../images/dahua.jpeg";
import hik from "../images/hik.jpeg";
import pr from "../images/pr.jpeg";
import panasonic from "../images/panasonic.jpeg";
import grandstr from "../images/grandstr.jpeg";
import zebra from "../images/zebra.jpeg";
import posss from "../images/posss.jpeg";
import epson from "../images/epson.jpeg";
import posssift from "../images/posssift.jpg";
import homeywell from "../images/homeywell.jpeg";

import Title from "./Title";
import Slider from "react-slick";
import { useTranslation } from "react-i18next";


function Working() {
    const { t } = useTranslation();
    
    const [Clients, setClients] = useState([

        {
            id: 31,
            name: "",
            img: samsung,
        },
        {
            id: 32,
            name: "",
            img: avaya,
        },
        {
            id: 33,
            name: "",
            img: came,
        },
        {
            id: 34,
            name: "",
            img: commax,
        },
        {
            id: 35,
            name: "",
            img: cisco,
        },
        {
            id: 36,
            name: "",
            img: zk,
        },
        {
            id: 37,
            name: "",
            img: faac,
        },
        {
            id: 38,
            name: "",
            img: ezvis,
        },
        {
            id: 39,
            name: "",
            img: dahua,
        },{
            id: 40,
            name: "",
            img: panasonic,
        },{
            id: 41,
            name: "",
            img: pr,
        },{
            id: 42,
            name: "",
            img: posss,
        },{
            id: 43,
            name: "",
            img: posssift,
        },{
            id: 44,
            name: "",
            img: homeywell,
        },{
            id: 45,
            name: "",
            img: zebra,
        },{
            id: 46,
            name: "",
            img: hik,
        },{
            id: 47,
            name: "",
            img: grandstr,
        },{
            id: 48,
            name: "",
            img: epson,
        },
    ]);

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,
        arrows: true,
        responsive: [
            {
                
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    arrows: true,
            },
            },
            {
                breakpoint: 700,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2,
                arrows: true,
            },
            },
            {
                breakpoint: 480,
                settings: {
                arrows: true,
                slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },{
                breakpoint: 200,
                settings: {
                arrows: true,
                slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    return (
        <section className="featured-rooms">
        <Title title={t("working")} />
        <div className="container">
            <div className="row mx-0">
                <div className="col-10 col-md-12 mx-auto">
                    <Slider {...settings}>
                        {Clients.map((item) => {
                            return (
                                <div key={item.id} className="col-6 col-md-4 p-3 text-center">
                                    <img src={item.img} className="d-inline-block" style={{maxWidth:"170px"}} width="100%" height="170" alt={item.img} />
                                </div>
                            );
                        })}
                    </Slider>
                </div>
            </div>
        </div>
    </section>
    )
}

export default Working
