import React from "react";
import "./App.css";

import Home from "./pages/Home";
import Error from "./pages/Error";

import { Route, Switch } from "react-router-dom";

import Navbar from "./components/Navbar";
import Services from "./pages/Services";
import About from "./pages/About";
import { ContactUs } from "./pages/ContactUs";

function App() {
    return (
        <>
            <Navbar />
            <Switch>
                <Route exact path="/" component={Home} />
                <Route path="/services" component={Services} />
                <Route path="/about-us" component={About} />
                <Route path="/contact-us" component={ContactUs} />
                <Route component={Error} />
            </Switch>
        </>
    );
}

export default App;
