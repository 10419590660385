import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { RoomProvider } from "./context";
import "bootstrap/dist/css/bootstrap.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import arabic from "./languages/ar";
import english from "./languages/en";

let defaultLang;
if(localStorage.getItem('langSolution')) {
    defaultLang = JSON.parse(localStorage.getItem('langSolution'));
    defaultLang = defaultLang;
}else {
    defaultLang = 'ar'
}


i18next.use(initReactI18next).init({
    resources: {
        ar: {
            translation: arabic
        },
        en: {
            translation: english
        }
    },
    lng: defaultLang,
    fallbackLng: defaultLang,
    interpolation: {
        escapeValue: false,
    },
});

ReactDOM.render(
    <RoomProvider>
        <Router>
            <App />
        </Router>
    </RoomProvider>,
    document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
