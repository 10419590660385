const english = {
    nameP: "Fundamentals of Solutions Trading Est",
    sub: "Solution EST",
    services: "Our services",
    SecurityCameras: "Security Cameras",
    AntiTheftSystems: "Burglar Alarm System",
    FingerprintSystems: "Attending And Leaving Systems",
    TelephoneSystems: "Central YP Phone",
    home: "Home",
    about: "About us",
    contactus: "Contact us",
    FeaturedClients: "Featured Clients",
    backhome: "Back To Home",
    aboutus: "About us",
    specialize :"specialize in : ",
    sec: "- Security systems (the latest types of surveillance cameras and alarms) ",
    sec2: "- The most accurate attendance and departure control devices",
    sec3: "- Centrals And Networks",
    sec4: "Security Cameras",
    sec5: "- Alarms Systems",
    sec6: "- Attendance Control Systems",
    sec7: "- Attendance And Departure Control Systems ",
    sec8: "- Centrals and networks ",
    working : "Our Partners",

    // 
    InterNetNetworks: "InterNet Networks",
    SoundSystems: "Sound Systems",
    MaintenanceContracts: "Maintenance Contracts",
    IntercomSystem: "Intercom System",
    // contact us
    textabout: "is a professional institution in the field of installation, operation and maintenance of integrated security systems, It has taken upon itself adding value to customer experience by formulating the best professional working staff under the culture concept of commitment and customer satisfaction",

    name : "Name",
    phone : "Phone",
    email : "Email",
    detils : "Details",
    send: "Send",
    newControl : "Access Control",
    network: "networks",
    anter: "Intercom",
    centerlat: "Centrals",
    soft: "Maintenance Contracts",
    POS: "POS",
    bigline: "Entry and exit gates for individuals and cars",
    info: "Our Information",
    systementer: "Attendance and departure systems",


}

export default english;