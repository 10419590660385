import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Banner from "../components/Banner";
import Hero from "../components/Hero";
import Title from "../components/Title";
import AboutImg from "../images/about.jpg";

function About() {

    const { t, i18n } = useTranslation();
    const { language } = i18n;
    return (
        <>
            <Hero>
                <Banner title={t('aboutus')} subtitle="Solution Solution EST">
                    <Link to="/" className="btn-primary font-main">
                        {t('backhome')}
                    </Link>
                </Banner>
            </Hero>
            <div className="container my-5">
                <Title title={t('aboutus')} />
                <div className="row">
                    <div className="col-10 mx-auto">
                        <h5 className="font-main text-capitalize text-center">
                            {t('textabout')}
                        </h5>
                    </div>
                </div>
                {/* <div className={language == "ar" ? "row mx-0 flex-row-reverse" : "row mx-0" }> */}
                {/* <div className="row mx-0"> */}
                    {/* <div className="col-12 col-md-10 mx-atuo text-center font-main" > */}
                        {/* <h5 className="font-main"> {t("specialize")} </h5> */}
                        {/* <p className="mt-2">
                            {t("sec")} 
                        </p> */}
                        {/* <h5>
                            {t('textabout')}
                        </h5> */}
                        {/* <p>{ t('sec2') }</p>
                        <p> { t("sec3") } </p>
                        <p> {t('sec4')} </p>
                        <p> {t('sec5')} </p>
                        <p> {t('sec6')} </p>
                        <p> {t('sec7')} </p>
                        <p> {t('sec8')} </p> */}
                    {/* </div> */}
                    {/* <div className="col-12 col-md-6">
                        <img src={AboutImg} className="img-fluid" />
                    </div> */}
                {/* </div> */}
            </div>
        </>
    );
}

export default About;
